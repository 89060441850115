//==========================================================================
//   Function: Document Listener
//   Description: Creates a Firebase document listener
//   Documentation:
//      https://firebase.google.com/docs/firestore/query-data/listen
//      https://firebase.google.com/docs/reference/android/com/google/firebase/firestore/DocumentSnapshot
//
//
//   //------------------------------------------------------
//   //   How to use this function: 
//   //------------------------------------------------------
//   
//      1. Create a useEffect
//      2. In the useEffect, define an 'onLoad' function, this will run once when the page first loads:
//         
//        function onLoad(document){
//
//          console.log('Getting the current document!', document);
//
//        }
//
//      3. In the useEffect, define an 'onChange' function, this will run each time a change occurs:
//         
//          function onChange(document, snapshot){
//          
//              console.log('Change happened!', document);
//          
//          }
//
//      4. In the useEffect, define an 'onError' function, this will run each time an error occurs:
//  
//          function onError(error){
//          
//              console.log('Something broke, error:', error);
//          
//          }
//
//      5. Import and call 'DocumentListener', note it returns a 'unsubscribe' callback. You WILL need this later!
//
//         const unsubscribe = DocumentListener('products', 'prd-123', onChange, onError);
//
//      6. Finally, we need ensure the document listener is shutdown when the component 'unmounts'
//
//        return () =>{
//  
//          unsubscribe();
//  
//        };
//
//
//     //------------------------------------------------------
//     //   Please see the example below:
//     //------------------------------------------------------
//
//      useEffect(()=>{
    
//        if (productId === undefined) return;
    
//        function onLoad(document){
//          setProductObject(document);
//        }
    
//        function onChange(document, previousValue){
//          if(previousValue === undefined) return;
//          if(previousValue?.pricingamount !== document?.pricingamount) setShowModal(true);   
//        }
    
//        function onError(error){
//          setRequestType('error-fatal');
//          setError(`Failed to get product information, unable to create the document listener error: ${error}`);  
//        }
    
//        const unsubscribe = DocumentListener('products', productId, onLoad, onChange, onError);
    
//        return () =>{
//          unsubscribe();
//        };
    
//      }, [productId]);
//
//
//------------------------------------------------------

//Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore, onSnapshot, doc, getDoc } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';


export default function DocumentListener(collectionId, documentId, onLoad, onChange, onError){

    try {

      //Firestore client
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      //Create document reference
      const docRef = doc(db, collectionId, documentId);

      //Get the document > call 'onLoad' function
      getDoc(docRef).then((document) => {

        if (document.exists()) {

          onLoad(document.data());

        } else {

          onLoad(undefined);

        }

      }).catch((error) => {

        onError(`DocumentListener has failed to complete, error: ${error}`);

      });

      var previousValue;

      // Invoke document listener > call 'onChange' function
      const unsubscribe = onSnapshot(docRef, (snapshot) => {

        if (snapshot.exists()) {

          const document = snapshot.data()

          if (document !== previousValue) {

            onChange(document, previousValue);
            previousValue = document;

          }

        } else {

          onChange(undefined, previousValue);

        }

      })

      // Return the callback function > Allows the caller to stop listening to changes
      return unsubscribe;

    } catch (error) {

        onError(`DocumentListener has failed to complete, error: ${error}`);

    }

}
