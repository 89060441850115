//================================================================
//  Page: Search 
//================================================================

//  Purpose: Search page for this application

//  Supporting documentation
//    - N/A

//================================================================

//Libraries
import React, { useReducer } from 'react';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';

//Functions
import queryDocument from '../../Library/QueryDocument';

//Images
import binIcon from '../../Components/Images/Icon_Bin_Blue.svg'
import submitButton from '../../Components/Images/Icon_Submit_Blue.svg';
import folderIcon from '../../Components/Images/Icon_Folder_Blue.svg';


//CSS
import './Search.css';


export default function Search() {

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {

        //Question input states
        'query': '',
        'queryMessage': '',
        'results': [],

      }
    )
  

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Sends chat request to firestore
    function chatHandler(e) {

      // Conditions
      if (e.code !== 'Enter' && e.code !== 'NumpadEnter' && e !== 'ManualClick') return;

      // Validate input
      if (formData.query.length === 0) {

        return setFormData({ 'queryMessage': 'Ummmm, are you going to search for something?' });

      }
      if (formData.query.length === 2) {

        return setFormData({ 'queryMessage': 'Search requires more than two characters.' });

      }

      // Reset search
      setFormData({
        'queryMessage': '',
        'results': [],
      });

      const query = formData.query.toLowerCase()
      const queryPromises = [];

      // Search by name
      queryPromises.push(

        queryDocument('files', [
            ['name', '==', query]
          ] 
        ) 
  
      )

      // Search by characters in name
      queryPromises.push(

        queryDocument('files', [
            ['namearray', 'array-contains-any', [query]]
          ] 
        ) 
  
      )

      Promise.all(queryPromises).then((arrays) => {

        const results = [
          ...arrays[0],
          ...arrays[1],
        ]

        // Filter results to be unique
        // https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
        const key = 'id';
        const unique = [...new Map(results.map(item => [item[key], item])).values()];

        if (unique.length === 0) {

          return setFormData({ 'queryMessage': 'No results' });

        }

        setFormData({
          'results': unique,
          'queryMessage': `Found ${unique.length} result(s)`
        });

      }).catch(() => {

        return setFormData({ 'queryMessage': 'Oops something went wrong :-(' });

      });

    };

  
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      requiredRoles={['isUser', 'isAdmin']}
      requiredRolesValue={true}
      status={'onload'}
      body={
        <div className='Search-Container'>

          {/* Search Input */}
          <input
            className='Search-Input'
            placeholder='Search for files here...'
            value={formData.query}
            onChange={(e) => {
              setFormData({ 'query': e.target.value })
            }}
            onKeyDown={(e) => chatHandler(e)}
          ></input>

          {/* Submit button */}
          <img className='Search-Submit' title='Submit' src={submitButton} alt='Submit' onClick={() => chatHandler('ManualClick')}></img>

          {/* Clear Button */}
          <img className='Search-Clear' title='Clear Search' src={binIcon} alt='Clear' onClick={() => {
          
            setFormData({ 
              'query': '',
              'results': [],
              'queryMessage': '',
            });
          
          }}></img>

          {/* Open Bucket */}
          <a className='Search-Bucket' href='https://console.cloud.google.com/storage/browser/aucommunitiesfilesearch74001.appspot.com' target='_blank' rel='noopener noreferrer'>
            <img title='Open Google bucket' src={folderIcon} alt='Open-Bucket'></img>
          </a>

          {/* Search Errors */}
          <div className='Search-Errors'>
              {formData.queryMessage}
          </div>
          
          {/* Results */}
          <div className='Search-Results'>

            {
              formData.results.map((file, index) => (

                <div key={index} className='Search-Results-Item'>

                  {/* Name & Link */}
                  <div className='Search-Item-Name'>
                    <a title='Open file' href={file.link} target='_blank' rel='noopener noreferrer'>
                      {file.folderpath}
                    </a>
                  </div>

                  {/* Folder Link */}
                  <div className='Search-Item-Folder'>
                    <a href={file.folderpathlink} target='_blank' rel='noopener noreferrer'>
                      <img title='Open folder' src={folderIcon} alt='Folder-Icon'></img>
                    </a>
                  </div>

                  {/* Metadata */}
                  <div className='Search-Item-Metadata'>

                    <div>
                      <h6>Created</h6>
                      <div>
                        {file.created}
                      </div>
                    </div>
                    <div>
                      <h6>Last Updated</h6>
                      <div>
                        {file.lastupdated}
                      </div>
                    </div>
                    <div>
                      <h6>Type</h6>
                      <div>
                        {file.type}
                      </div>
                    </div>
                    <div>
                      <h6>Size (MB)</h6>
                      <div>
                        {file.size}
                      </div>
                    </div>
                  </div>

                </div>

              ))
            }

          </div>

        </div>
        }
      ></PageComponent>
    )
}