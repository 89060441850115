//================================================================
//  Component: Login
//================================================================

//  Purpose: Trigger a login through Firebase

//  Properties:
//    - N/A

//  Example:
//    <Login></Login>    

//================================================================

import React, {useContext} from 'react'
import { getAuth, signInWithRedirect } from "firebase/auth";
import {GetFireBaseProvider, SetAppStatus, SetAppErrors} from './GlobalContexts';

export default function Login() {

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/microsoft-oauth#handle_the_sign-in_flow_with_the_firebase_sdk
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  // useContexts & useStates
  //------------------------------------------------------

    //Firebase
    const Provider = useContext(GetFireBaseProvider);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  // This function triggers a redirect signin > AuthProvider.js will handle the results
  // https://firebase.google.com/docs/auth/web/microsoft-oauth
  //------------------------------------------------------

    function SignIn(){

      signInWithRedirect(auth, Provider).catch((error) =>{

        setAppStatus('failed');
        setAppErrors(error.message);

      });

    }

  //------------------------------------------------------
  //  Return
  //------------------------------------------------------

    return (
      <button className="Primary-Button" onClick={SignIn}>Sign in</button>
    )

  //------------------------------------------------------
}